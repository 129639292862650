<template>
  <v-card>
    <v-card-title primary-title>
      <h1>Suivi du parc wagons</h1>
    </v-card-title>

    <v-dialog v-model="dialogWagonsPool" persistent :overlay="false" max-width="750px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Wagons en réforme 
        </v-card-title>
        <v-card-text>
          <v-simple-table fixed-header height="300px" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Numéro</th>
                  <th>Etat</th>
                  <th>Lieu</th>
                  <th>Entrée réelle</th>
                  <th>Sortie prévue</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(wagon, indexWagon) in wagonsPoolReforme" :key="indexWagon">
                  <td>{{ wagon.numero }}</td>
                  <td>{{ wagon.poolStade != undefined ? (wagon.poolStade.indexOf('ENC') >= 0 ? "En cours" : "A traiter"): "" }}</td>
                  <td>{{ wagon.poolLieu }}</td>
                  <td>{{ wagon.poolEntreeReelle != undefined ? wagon.poolEntreeReelle.substr(0,10) : '' }}</td>
                  <td>{{ wagon.poolSortiePrevue != undefined ? wagon.poolSortiePrevue.substr(0,10) : '' }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogWagonsPool = false">fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWagons" persistent :overlay="false" max-width="750px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Wagons
        </v-card-title>
        <v-card-text>
          <v-simple-table fixed-header height="300px" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Numéro</th>
                  <th>Lieu</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(wagon, indexWagon) in wagonsFiltres" :key="indexWagon">
                  <td>{{ wagon.numero }}</td>
                  <td>{{ wagon.lieu }}</td>
                  <td>{{ wagon.date != undefined ? wagon.date.substr(0,10) : '' }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogWagons = false">fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWagonsInconnu" persistent :overlay="false" max-width="750px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Wagons en réforme mais inconnu
        </v-card-title>
        <v-card-text>
          <v-simple-table fixed-header height="300px" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Numéro</th>
                  <th>Etat</th>
                  <th>Lieu</th>
                  <th>Entrée réelle</th>
                  <th>Sortie prévue</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(wagon, indexWagon) in wagonsInconnus" :key="indexWagon">
                  <td>{{ wagon.numero }}</td>
                  <td>{{ wagon.stade != undefined ? (wagon.stade.indexOf('ENC') >= 0 ? "En cours" : "A traiter"): "" }}</td>
                  <td>{{ wagon.lieu }}</td>
                  <td>{{ wagon.entreeReelle != undefined ? wagon.entreeReelle.substr(0,10) : '' }}</td>
                  <td>{{ wagon.sortiePrevue != undefined ? wagon.sortiePrevue.substr(0,10) : '' }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogWagonsInconnu = false">fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-card-text>

      <v-tabs v-model="tab">
        <v-tab>Comptage</v-tab>
        <v-tab>Wagon</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item>
          <v-progress-linear indeterminate color="teal" v-if="loading"></v-progress-linear>
          <h-group style="align-items: start;">
            <v-group>
              <!-- Synthèse -->
              <h3 style="width: 100%; text-align: left;" class="blue--text darken-3 mt-4">Informations Ermewa du : {{ horodatageWagonsPool != undefined ? horodatageWagonsPool.substr(0, 10)  : ""}}</h3>
              <table>
                <tbody>
                  <!-- Wagons enregistrés -->
                  <tr>
                    <td class="etiquette">Wagons enregistrés</td>
                    <td><div class="valeur">{{ wagonsPoolComplet.length }}</div></td>
                    <td class="text-left"><v-btn x-small text class="ml-2" color="green" @click="lireWagons()"><v-icon>mdi-refresh</v-icon></v-btn></td>
                  </tr>
                  <!-- Wagons Vosges + Italie -->
                  <tr>
                    <td class="etiquette">Wagons Vosges + Italie</td>
                    <td><div class="valeur">{{ wagonsPool.length }}</div></td>
                    <td></td>
                  </tr>
                  <!-- Wagons en circulation -->
                  <tr>
                    <td class="etiquette">Wagons en circulation</td>
                    <td><div class="valeur">{{ ctrWagonsOk }}</div></td>
                    <td class="text-left"><v-chip color="green" class="white--text">{{ ratioWagonsOkTotal }} %</v-chip></td>
                  </tr>
                  <!-- Wagons en réforme -->
                  <tr>
                    <td class="etiquette">Wagons en réforme</td>
                    <td><div class="valeur">{{ ctrWagonsPool }}</div></td>
                    <td class="text-left">
                      <v-chip color="green" class="white--text">{{ ratioWagonsPoolTotal }} %</v-chip>
                      <v-btn x-small text class="ml-2" color="green" @click="dialogWagonsPool = true"><v-icon>mdi-eye</v-icon></v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Wagons par lieu -->
              <h3 style="width: 100%; text-align: left;" class="blue--text darken-3 mt-4">Wagons par lieu</h3>
              <table>
                <tbody>
                  <tr v-for="(lieu, indexLieu) in lieux" :key="indexLieu">
                    <td class="etiquette">{{ lieu.nom }}</td>
                    <td class="valeur">{{ lieu.nb }} </td>
                    <td><v-btn x-small text class="ml-2" color="green" @click="boutonVoirWagonsLieu(lieu)"><v-icon>mdi-eye</v-icon></v-btn></td>
                  </tr>
                </tbody>
              </table>

              <h3 style="width: 100%; text-align: left;" class="blue--text darken-3 mt-4">Traçage par période</h3>
              <table>
                <thead>
                  <tr>
                    <td class="etiquette">Période</td>
                    <td style="width: 100px">Tracés</td>
                    <td style="width: 100px">Non tracés</td></tr>
                </thead>
                <tbody>
                  <tr v-for="(periode, index) in wagonsPeriodes" :key="index">
                    <td class="etiquette">{{ periode.titre }}</td>
                    <td class="valeur">{{ periode.wagonsTraces.length }} <v-btn x-small text class="ml-2" color="green" @click="voirWagonsTraces(periode)"><v-icon>mdi-eye</v-icon></v-btn></td>
                    <td class="valeur">{{ wagons.length - periode.wagonsTraces.length }} <v-btn x-small text class="ml-2" color="green" @click="voirWagonsNonTraces(periode)"><v-icon>mdi-eye</v-icon></v-btn></td>
                  </tr>
                </tbody>
              </table>
            </v-group>

            <!-- Liste -->
            <v-group class="ml-5"> 
              <div>{{ titreListe }}</div>
              <v-simple-table dense fixed-header  height="500px" style="background-color: #F8F8F8;">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <td>Numero</td>
                    <td>Date WR</td>
                    <td>Date WD</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody  >
                  <tr v-for="(wagon, index) in wagonsAffiches" :key="index">
                    <td>{{ wagon.numeroAbrege }}</td>
                    <td>{{ computeDate(wagon.dateWR) }}</td>
                    <td>{{ computeDate(wagon.dateWD) }}</td>
                    <td><v-btn x-small text class="ml-2" color="green" @click="voirConstatsWR(wagon)"><v-icon>mdi-eye</v-icon></v-btn></td>
                  </tr>
                </tbody>

                </template>
              </v-simple-table>
            </v-group>

            <v-group class="ml-4">
              <div>Constats</div>
              <v-simple-table dense fixed-header  height="500px" style="background-color: #F8F8F8;">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <td>Numero</td>
                    <td>Date</td>
                    <td>Lieu</td>
                    <td>Constat</td>
                    <td>Avarie</td>
                  </tr>
                </thead>
                <tbody  >
                  <tr v-for="(constat, indexConstat) in constats" :key="indexConstat">
                    <td>{{ constat.numeroWagon }}</td>
                    <td>{{ computeDate(constat.date) }}</td>
                    <td>{{ constat.lieu }}</td>
                    <td>{{ constat.typeConstat }}</td>
                    <td>{{ constat.labelAvarie }}</td>
                  </tr>
                </tbody>

                </template>
              </v-simple-table>

            </v-group>
          </h-group>
        </v-tab-item>

        <v-tab-item>
          <v-progress-linear indeterminate color="teal" v-if="loading"></v-progress-linear>
          <h-group style="background-color: #F8F8F8;" class="mb-1">
            <div class="mr-3">Saisir un numéro de wagon<br/>(au moins les 5 derniers chiffres)</div>
            <div style="width: 160px;"  class="mr-3">
              <v-text-field label="N° de wagon" v-model="numeroWagonPourSuivi"></v-text-field>
            </div>
            <div style="width: 80px;"  class="mr-5 ml-5">
              <v-text-field type="number" label="Nb de mois" v-model="nbMoisPourSuivi"></v-text-field>
            </div>
            <v-checkbox label="Inclure les rapports d'intervention" v-model="inclureRapportIntervention" :value="true" class="mr-5"></v-checkbox>
            <v-btn color="success" class="mr-5" @click="rechercherSuiviClick">Rechercher</v-btn>
            <v-btn color="success" class="mr-3" @click="exporterSuiviClick" :disabled="this.suiviWagon.length <= 0">Exporter</v-btn>
          </h-group>
            <v-group class="ml-4">
              <v-simple-table dense fixed-header  height="500px" style="background-color: #F8F8F8;">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <td>Numero</td>
                    <td>Source</td>
                    <td>Date</td>
                    <td>Lieu</td>
                    <td>Commentaire</td>
                  </tr>
                </thead>
                <tbody  >
                  <tr v-for="(suivi, indexSuivi) in suiviWagon" :key="indexSuivi">
                    <td>{{ suivi.numero }}</td>
                    <td>{{ suivi.source }}</td>
                    <td>{{ computeDateHeure(suivi.date) }}</td>
                    <td>{{ suivi.lieu }}</td>
                    <td class="text-left" :style="'color: ' + computeCouleurSuiviWagon(suivi.type)">{{ suivi.commentaire }}</td>
                  </tr>
                </tbody>

                </template>
              </v-simple-table>

            </v-group>

        </v-tab-item>
      </v-tabs-items>


    </v-card-text>

  </v-card>
</template>

<script>
import axios from 'axios';
import HGroup from '@/components/flex/HGroup.vue';
const { exportJsonToExcel } = window.jsonToExcel;

export default {
  components: {HGroup},

  data() {
    return {
      dialogWagonsPool:false,
      dialogWagonsInconnu: false,
      dialogWagons: false,
      titreListe: "",
      wagons: [],
      wagonsPoolComplet: [],
      wagonsPool: [],
      wagonsPoolReforme: [],
      wagonsInconnus: [],
      wagonsFiltres: [],
      horodatageWagonsPool: undefined,
      ctrWagonsTotal: 0,
      ctrWagonsPool : 0,
      ctrWagonsOk : 0,
      ratioWagonsPoolTotal : 0,
      ratioWagonsOkTotal : 0,
      constats: [],
      wagonsUtilises1s: [],
      wagonsUtilises2s: [],
      wagonsUtilises4s: [],
      wagonsUtilises3m: [],
      wagonsAffiches: [],
      wagonsPeriodes: [
        {titre:"Dernière semaine",nbJours: 7, wagonsTraces: [], wagonsNonTraces: []},
        {titre:"2 dernières semaines",nbJours: 14, wagonsTraces: [], wagonsNonTraces: []},
        {titre:"4 dernières semaines",nbJours: 28, wagonsTraces: [], wagonsNonTraces: []},
        {titre:"3 derniers mois",nbJours: 30.5*3, wagonsTraces: [], wagonsNonTraces: []},
      ],
      lieux: [
        {nom: "VOSGES", nb: 0}, {nom: "MRY", nb: 0}, {nom: "MBL", nb: 0}, {nom: "ARS", nb: 0}, {nom: "LONATO", nb: 0}, {nom: "?", nb: 0}
      ],
      loading: false,
      tab: 0,
      numeroWagonPourSuivi: "",
      suiviWagon:[],
      inclureRapportIntervention: true,
      nbMoisPourSuivi: 3
    }
  },

  mounted() {
    this.lireWagons();
  },

  watch: {
    $route(to) {
      if (to.url.startsWith('/wagons/')) {
        this.lireWagons()
      }
    }
  },

  methods: {
    async lireWagons() {
      this.loading = true
      await axios.get("/api/wgWagons/analyserWagons?access_token=" + this.$user.id)
      .then(function (response) {
          this.loading = false
          this.wagons = response.data
          const now = new Date()
          this.wagonsPeriodes.forEach(periode => {
            const datePeriode = new Date(now.getTime() - 86400 * 1000 * periode.nbJours)
            periode.wagonsTraces = this.wagons.filter(w => { const d = new Date(w.date); return d > datePeriode}) 
            periode.wagonsNonTraces = this.wagons.filter(x => !periode.wagonsTraces.includes(x));
          });
          setTimeout(function() {
            this.lireWagonsPool();
          }.bind(this))

        }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.wagons = [];
          this.loading = false
        }
        else {
          this.afficherErreur(error.message)
          this.wagons = [];
          this.loading = false
        }
      });
    },

    async lireWagonsPool() {
      this.loading = true
      await axios.get("/api/wgWagons/wagonsPool?access_token=" + this.$user.id)
      .then(function (response) {
          this.loading = false
          this.wagonsPoolComplet = response.data.wagons
          this.wagonsPool = response.data.wagons.filter(w => w.numero.startsWith("3387354"))
          this.wagonsPoolReforme = this.wagonsPool.filter(w => w.poolPresent)
          this.horodatageWagonsPool = response.data.poolHorodatage
          this.ctrWagonsTotal = this.wagonsPool.length
          this.ctrWagonsPool = this.wagonsPool.filter(w => w.poolPresent).length
          this.ctrWagonsOk = this.ctrWagonsTotal - this.ctrWagonsPool
          this.ratioWagonsOkTotal = Math.round(100 * this.ctrWagonsOk / this.ctrWagonsTotal)
          this.ratioWagonsPoolTotal = Math.round(100 * this.ctrWagonsPool / this.ctrWagonsTotal)
          console.log("this.wagonsPool.length", this.wagonsPool.length)
          this.wagonsInconnus = []
          this.wagonsPool.forEach(wagon => {
            const w0 = this.wagons.find(w => wagon.numero == w.numeroAbrege)
            if (w0 == undefined) {
              this.wagonsInconnus.push(wagon)
            }
          })
          const lieux = JSON.parse(JSON.stringify(this.lieux))
          lieux.forEach(l => {
            l.wagons = this.wagonsPool.filter(w => (w.lieu == l.nom) && !w.poolPresent)
            l.nb = l.wagons.length
          })
          this.lieux = lieux

        }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.wagons = [];
          this.loading = false
        }
        else {
          this.afficherErreur(error.message)
          this.wagons = [];
          this.loading = false
        }
      });
    },

    async voirConstatsWR(wagon) {
      console.log(wagon)
      await axios.get("/api/wgWagons/constatswr?numeroWagon=" + wagon.numeroAbrege + "&access_token=" + this.$user.id)
      .then(function (response) {
          this.loading = false
          this.constats = response.data
        }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.wagons = [];
          this.loading = false
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
          this.wagons = [];
          this.loading = false
        }
      });

    },

    voirWagonsTraces(periode) {
      this.titreListe = "Wagons tracés - " + periode.titre
      this.wagonsAffiches = periode.wagonsTraces
      this.$forceUpdate();
    },

    voirWagonsNonTraces(periode) {
      this.titreListe = "Wagons non tracés - " + periode.titre
      this.wagonsAffiches = periode.wagonsNonTraces
    },

    computeDate(date) {
      return date != undefined ?  date.substr(0, 10) : ""
    },

    computeDateHeure(date) {
      return date != undefined ?  date.substr(0, 16).replace(/T/g, ' ') : ""
    },

    boutonVoirWagonsLieu(lieu) {
      this.wagonsFiltres = lieu.wagons;
      this.dialogWagons = true      
    },

    async rechercherSuiviClick() {
      const numwagon = this.numeroWagonPourSuivi.replace(/ /g, "").replace(/-/g, "")
      if (numwagon.length < 5) {
        return
      }
      this.loading = true
      try {
        // Chargement des trajets
        var response = await axios.get("/api/wgWagons/suiviWagon?numero=" + numwagon + "&nbMois=" + this.nbMoisPourSuivi + "&access_token=" + this.$user.id)
        const suiviWagon = []
        for(var idx = 0; idx < response.data.length; idx++) {
          const suivi = response.data[idx]
          suiviWagon.push({ numero: suivi.wgNumero, source: "WD", date: suivi.frDepartDateReelle != null ? suivi.frDepartDateReelle : suivi.frDepartDateTheorique, lieu: suivi.frDepartLieu, commentaire: "Départ", type: 0 })
          suiviWagon.push({ numero: suivi.wgNumero, source: "WD", date: suivi.frArriveeDateReelle != null ? suivi.frArriveeDateReelle : suivi.frArriveeDateTheorique, lieu: suivi.frArriveeLieu, commentaire: "Arrivée", type: 0 })
        }
        if (suiviWagon.length > 0) {
          // Chargement des constats
          response = await axios.get("/api/wgWagons/constatswr?numeroWagon=" + suiviWagon[0].numero + "&nbMois=" + this.nbMoisPourSuivi + "&access_token=" + this.$user.id)
          for(idx = 0; idx < response.data.length; idx++) {
            const constat = response.data[idx]
            suiviWagon.push({ numero: constat.numeroWagon.replace(/ /g,'').replace(/-/g, ''), source: "WR Constat", date: constat.date, lieu: constat.lieu + " " + constat.categorie, commentaire: (constat.typeConstat.toLowerCase() == "avaries") ? "Avarie : " + constat.numeroAvarie + " - " + constat.labelAvarie : "Constat " + constat.typeConstat, type: (constat.typeConstat.toLowerCase() == "avaries") ? 2 : 1})
          }
          this.suiviWagon = suiviWagon.sort((a, b) => new Date(b.date) - new Date(a.date));

          // Chargement des rapports d'intervention
          if (this.inclureRapportIntervention) {
            response = await axios.get("/api/wgWagons/rapportswr?numeroWagon=" + suiviWagon[0].numero + "&nbMois=" + this.nbMoisPourSuivi + "&access_token=" + this.$user.id)
            for(idx = 0; idx < response.data.length; idx++) {
              const rapport = response.data[idx]
              for(var idx2 = 0; idx2 < rapport.constatrapport.length; idx2++) {
                const constatrapport = rapport.constatrapport[idx2]
                const date = rapport.date.substr(0,10) + " 22:00:00"
                suiviWagon.push({ numero: rapport.numeroWagon.replace(/ /g,'').replace(/-/g, ''), source: "WR Pool", date: date, lieu: rapport.lieu, commentaire: "Rapport : "+ constatrapport.CRI_Label + " : " + constatrapport.CRI_Total + " €", type: 3 })
              }
            }
          }
          this.suiviWagon = suiviWagon.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        else {
          this.suiviWagon = suiviWagon
        }
        this.loading = false
      }
      catch(error) {
        this.loading = false
        console.error(error)
        this.suiviWagon = [];
      }
    },

    computeCouleurSuiviWagon(type) {
      switch(type) {
        case 0: return 'blue'
        case 1: return 'green'
        case 2: return 'red'
        case 3: return '#FF8000'
      }
      return 'black'
    },

    exporterSuiviClick() {
      if (this.suiviWagon.length > 0) {
        const suiviWagonExcel = [['Wagon', 'Source', 'Date', 'Lieu', 'Commentaire']]
        for(var idx = 0; idx < this.suiviWagon.length; idx++) {
          const suivi = this.suiviWagon[idx]
          suiviWagonExcel.push([
            suivi.numero.toString(),
            suivi.source,
            suivi.date != undefined ? suivi.date.replace(/T/g, " ").replace(/.000Z/g, "").replace(/-/g, "/") : "",
            suivi.lieu,
            suivi.commentaire
          ])
        }
        const numwagon = this.suiviWagon[0].numero
        exportJsonToExcel(
          "suivi-wagon-" + numwagon + ".xlsx",
          [
            {
              sheetName: "Suivi",
              data: suiviWagonExcel,
              formatAsTable: false
            }
          ],
          {
            beforeSave: function(workbook) {
              workbook.getWorksheet(1).eachRow(function(row, rowNumber) {
                if (rowNumber > 1) {
                  console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
                  row.getCell(5).alignment = { vertical: 'middle', horizontal: 'left' }
                  var color = 'FF000000'
                  const type = this.suiviWagon[rowNumber - 2].type
                  if (type == 0) color ='FF0000FF'
                  if (type == 1) color ='FF008000'
                  if (type == 2) color ='FFFF0000'
                  if (type == 3) color ='FFFF8000'
                  console.log(color);
                  row.getCell(5).font = { 'color': {'argb': color} }
                }
              }.bind(this))
            }.bind(this)
          }
        )
        //this.$exporterCsv(suiviWagonExcel, "suivi-wagon-" + numwagon + ".csv")
      }
    }
  }
}
</script>

<style scoped>

.valeur {
  background-color: black;
  color: yellow;
  padding: 3px;
  margin: 1px;
  width: 60px;
  margin-right: 10px;;
}

.etiquette {
  width: 180px;
  text-align: right;
  padding-right: 10px;
}
</style>